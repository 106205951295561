import React, { useState, useEffect } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import { navigate } from "gatsby"
//import uuid from 'react-uuid'

import {
    Container,
    Flex,
    Box,
    Heading,
    Text,
    IconButton,
    Button,
    VStack,
    HStack,
    Wrap,
    WrapItem,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    //useColorModeValue as mode,
} from '@chakra-ui/react';
import {
    MdFacebook,
    MdOutlineEmail,
} from 'react-icons/md';
import { BsPerson, BsInstagram } from 'react-icons/bs';



import Recaptcha from "react-recaptcha"

const encode = data => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
}
function ContactForm() {
    const [token, setToken] = useState(null)

    useEffect(() => {
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js"
        script.async = true
        script.defer = true
        document.body.appendChild(script)
    }, [])
    return (
        <Formik
            initialValues={{ fullName: "", email: "" }}
            validate={values => {
                const errors = {}
                if (!values.fullName) {
                    errors.fullName = <p style={{ color: "red", fontSize: "0.7em" }}>&nbsp;*Requerido</p>
                } else if (values.fullName.length <= 1) {
                    errors.fullName = "must be at least 2 characters"
                }
                if (!values.email) {
                    errors.email = <p style={{ color: "red", fontSize: "0.7em" }} color="red">&nbsp;*Requerido</p>
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                ) {
                    errors.email = "Invalid email address"
                }
                return errors
            }}
            onSubmit={(data, { resetForm }) => {
                console.log(data)
                if (token !== null) {
                    fetch("/", {
                        method: "POST",
                        headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        body: encode({
                            "form-name": "contact-form",
                            ...data,
                            "g-recaptcha-response": token,
                        }),
                    })
                        .then(() => {
                            //alert("send")
                            resetForm()

                            /* const event_id = uuid()
                            if (event_id) {
                                if (process.env.NODE_ENV === `production` && typeof window.fbq === `function`) {
                                    //alert(event_id);
                                    console.log('Lead: ' + event_id);

                                    window.fbq('trackSingle', '686406995880452', 'Lead', {}, { eventID: `${event_id}` });

                                    window.dataLayer.push({
                                        'event': 'spa_lead',
                                        'route_change_event_uuid': `${event_id}`,
                                    });
                                }

                            } else {
                                console.log('Error: sin datos event_uuid en dataLayer.');
                            } */












                            navigate('/registro-completado')
                        })
                        .catch(error => alert(error))
                }
            }}
        >
            <Container maxW="full" my={6} centerContent overflow="hidden">
                <Flex>
                    <Box
                        bg="#02054B"
                        //bg="gradient.blue.start"
                        color="white"
                        borderRadius="lg"
                        m={{ sm: 4, md: 16, lg: 10 }}
                        p={{ sm: 5, md: 5, lg: 16 }}>
                        <Box p={4}>
                            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
                                <WrapItem>
                                    <Box>
                                        <Heading>Escríbenos 👋</Heading>
                                        <Text maxWidth={'400px'} mt={{ sm: 3, md: 3, lg: 5 }} color="gray.500">
                                            Dejanos saber cualquier duda, o simplemente algún saludo o comentario.
                                        </Text>
                                        {/* <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                                            <VStack pl={0} spacing={3} alignItems="flex-start">
                                                <Button
                                                    size="md"
                                                    height="48px"
                                                    width="200px"
                                                    variant="ghost"
                                                    color="#DCE2FF"
                                                    _hover={{ border: '2px solid #1C6FEB' }}
                                                    leftIcon={<MdPhone color="#1970F1" size="20px" />}>
                                                    +58 4249495094
                                                </Button>
                                                <Button
                                                    size="md"
                                                    height="48px"
                                                    width="200px"
                                                    variant="ghost"
                                                    color="#DCE2FF"
                                                    _hover={{ border: '2px solid #1C6FEB' }}
                                                    leftIcon={<MdEmail color="#1970F1" size="20px" />}>
                                                    info@daniosdigital.com
                                                </Button>
                                                <Button
                                                    size="md"
                                                    height="48px"
                                                    width="200px"
                                                    variant="ghost"
                                                    color="#DCE2FF"
                                                    _hover={{ border: '2px solid #1C6FEB' }}
                                                    leftIcon={<MdLocationOn color="#1970F1" size="20px" />}>
                                                    Lecherías, Venezuela.
                                                </Button>
                                            </VStack>
                                        </Box> */}
                                        <HStack
                                            mt={{ lg: 10, md: 10 }}
                                            spacing={5}

                                            alignItems="flex-start"
                                        >
                                            <IconButton
                                                as="a"
                                                href="https://www.facebook.com/daniosdigital"
                                                aria-label="facebook"
                                                variant="ghost"
                                                size="lg"
                                                isRound={true}
                                                _hover={{ bg: '#0D74FF' }}
                                                icon={<MdFacebook size="28px" />}
                                            />
                                            <IconButton
                                                as="a"
                                                href="https://www.instagram.com/daniosdigital"
                                                aria-label="instagram"
                                                variant="ghost"
                                                size="lg"
                                                isRound={true}
                                                _hover={{ bg: '#0D74FF' }}
                                                icon={<BsInstagram size="28px" />}
                                            />

                                        </HStack>
                                    </Box>
                                </WrapItem>
                                <WrapItem>
                                    <Box bg="white" borderRadius="lg">
                                        <Box m={8} color="#0B0E3F">
                                            <VStack spacing={5}>
                                                <Form
                                                    name="contact-form"
                                                    data-netlify="true"
                                                    data-netlify-honeypot="bot-field"
                                                    data-netlify-recaptcha="true"
                                                >
                                                    <Field type="hidden" name="form-name" />
                                                    <Field type="hidden" name="bot-field" />
                                                    <FormControl id="fullName">
                                                        <FormLabel htmlFor="fullName">Nombre Completo</FormLabel>
                                                        <InputGroup borderColor="#E0E1E7">
                                                            <InputLeftElement
                                                                pointerEvents="none"
                                                                children={<BsPerson color="gray.800" />}
                                                            />
                                                            <Input name="fullName" type="text" size="md" />
                                                            <ErrorMessage name="fullName" />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl id="email">
                                                        <FormLabel htmlFor="email">Email</FormLabel>
                                                        <InputGroup borderColor="#E0E1E7">
                                                            <InputLeftElement
                                                                pointerEvents="none"
                                                                children={<MdOutlineEmail color="gray.800" />}
                                                            />
                                                            <Input name="email" type="text" size="md" />
                                                            <ErrorMessage color="red" name="email" />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl id="message">
                                                        <FormLabel htmlFor="message">Mensaje</FormLabel>
                                                        <Textarea
                                                            name="message"
                                                            borderColor="gray.300"
                                                            _hover={{
                                                                borderRadius: 'gray.300',
                                                            }}
                                                            placeholder="Mensaje"
                                                        />
                                                    </FormControl>
                                                    <FormControl my={3}>
                                                        {/* <Recaptcha
                                                            sitekey={process.env.SITE_RECAPTCHA_KEY}
                                                            render="explicit"
                                                            theme="light"
                                                            verifyCallback={response => {
                                                                setToken(response)
                                                            }}
                                                            onloadCallback={() => {
                                                                console.log("done loading!")
                                                            }}
                                                        /> */}
                                                    </FormControl>
                                                    <FormControl id="submit" float="right">
                                                        <Button
                                                            width={'100%'}
                                                            type="submit"
                                                            variant="solid"
                                                            bg="#0D74FF"
                                                            color="white"
                                                            _hover={{}}>
                                                            Enviar
                                                        </Button>
                                                    </FormControl>
                                                </Form>
                                            </VStack>
                                        </Box>
                                    </Box>
                                </WrapItem>
                            </Wrap>
                        </Box>
                    </Box>
                </Flex>
            </Container>
        </Formik>
    )
}

export default ContactForm







