import * as React from "react"

import Layout from "../components/layouts/layout"
import Seo from "../components/utils/seo"
import ContactoHero from "../components/sections/contactoHero"
import ContactForm from "../components/sections/contactForm"
import Cta from "../components/sections/cta"
//import uuid from 'react-uuid'


//const isBrowser = () => typeof window !== "undefined"
//isBrowser() && window......

const Nosotros = () => {

    //const event_id = uuid()
    const event_id = null
    if (event_id) {
        if (process.env.NODE_ENV === `production` && typeof window !== 'undefined' && typeof window.fbq === `function`) {
            //alert(event_id);
            console.log('ViewContent: ' + event_id);

            //fbq('trackSingle', '686406995880452', 'ViewContent', {}, { eventID: '{{dlv - eventID}}' });
            //fbq('trackCustom', 'ShareDiscount', {},{ eventID: '{{dlv - eventID}}' });


            //window.fbq('trackSingle', '686406995880452', 'ViewContent', {}, { eventID: `${event_id}` });
            //window.fbq('track', 'ViewContent', { content_name: 'Test' }, { eventID: `${event_id}` });

            window.dataLayer.push({
                'event': 'spa_view_content',
                'route_change_event_uuid': `${event_id}`,
            });
        }

    } else {
        //console.log('Error: sin datos event_uuid en dataLayer.');
    }

    return (
        <Layout>
            <Seo title="Contacto" />
            <ContactoHero />
            <ContactForm />
            <Cta />
        </Layout>
    )
}

export default Nosotros