import { Box, Center, Heading, Text } from '@chakra-ui/react'
import * as React from 'react'

const ContactoHero = () => {
    return (
        <Box
            as="section"
            bg="gray.800"
            py="12"

            position="relative"
            h={{ base: '280px', md: '320px' }}
            bgImage="url(https://images.unsplash.com/photo-1573164713619-24c711fe7878?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1500&q=80)"
            bgSize="cover"
            bgPosition="center"
            _after={{
                content: `""`,
                display: 'block',
                w: 'full',
                h: 'full',
                bg: 'blackAlpha.800',
                position: 'absolute',
                inset: 0,
                zIndex: 0,
            }}
        >
            <Box
                maxW={{ base: 'xl', md: '7xl' }}
                mx="auto"
                px={{ base: '6', md: '8' }}
                h="full"
                zIndex={1}
                position="relative"
            >
                <Center flexDirection="column" textAlign="center" color="white" h="full">
                    <Heading size="2xl" fontWeight="extrabold">
                        CONTACTO
                    </Heading>
                    <Text fontSize="lg" fontWeight="medium" mt="3">
                        Estamos emocionados por iniciar el camino hacia las primeras visitas que traeran nuevas sonrisas.
                    </Text>
                </Center>
            </Box>

        </Box>
    )
}

export default ContactoHero